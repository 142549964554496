#controllerPage button, #controller2 button{
    border: 1px solid black;
    border-radius: 0.2em;
    box-shadow: black 0 1px 1px 0;
}

#controllerPage button:active{
    border: 1px solid black;
    border-radius: 0.2em;
    box-shadow: inset 0 0 5px #a7a6a6;
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"]{
    border: 1px solid black;
    border-radius: 0.3em;
}

#controllerPage :is(#parametersControl, #lists){
    flex: 1
}


@media screen and (max-width: 1728px){
    #controllerPage #parametersControl{
        flex: 4
    }

    #controllerPage #lists{
        flex: 5
    }
}

@media screen and (max-width: 1469px){
    #controllerPage #parametersControl{
        flex: 4
    }

    #controllerPage #lists{
        flex: 7
    }
}
