.img-zoom-lens {
    position: absolute;
    border: 1px solid #d4d4d4;
    /*set the size of the lens:*/
    width: 50px;
    height: 50px;
    z-index: 2;
}

#original {
    margin-bottom: 20px;
}

#result {
    overflow: hidden;
    border: 1px solid white;
    position: absolute;
    opacity: 1;
    background-color: black;
}

.mapSVG {
    position: absolute;
    top: 0;
    left: 0;
}



