.chat {
    width: 400px;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
    z-Index: 1;
}

.chat:not(.controllerChat){
    cursor: grab;
}

.chat:not(.controllerChat):active {
    cursor: grabbing;
}

.chat .direct {
    border-color: limegreen;
}

.newMessage {
    background-color: slateblue;
}

.direct.newMessage {
    background-color: limegreen;
}

.toast{
    max-height: 100vh;
    overflow: hidden;
    padding: 1em;
    padding-top: 1.3em;
}

.toast.noHeight{
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}
