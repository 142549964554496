body{
    margin: 0;
    padding: 0;
    font-family: 'Merriweather', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    height: 100vh;
}

.account{
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    display: flex;
    overflow-y: auto;
    background: #eee;
}

.account.account--photo{
    background: #6fb9fb;
}

.account.account--photo .account__card{
    background-color: rgba(0, 10, 16, 0.8);
    color: #fff;
}

.account.account--photo .form__form-group-icon, .account.account--photo .form__form-group-button{
    background: transparent;
}

.account.account--photo .form__form-group-icon, .account.account--photo input:not(:last-child){
    border-right: none;
}

.account.account--photo .form__form-group-button, .account.account--photo input{
    border-left: none;
}

.account.account--photo .form__form-group-button.active{
    border-color: #eff1f5;
}

.account.account--photo .form__form-group-button.active svg{
    fill: #eee;
}

.account.account--photo input{
    color: #fff;
}

.account.account--photo input:focus{
    border-color: #eff1f5;
}

.account.account--photo p{
    color: #fff;
}

.account.account--photo .account__title{
    color: #fff;
}

.account__wrapper{
    margin: auto;
    padding: 10px;
}

.account__card{
    background-color: #fff;
    margin: 30px auto auto auto;
    padding: 50px 60px;
    width: 500px;
}

.account__profile{
    text-align: center;
}

.account__btns{
    display: flex;
    width: calc(100% + 10px);
    margin: -10px 0 -20px -10px;
}

.account__btns a{
    margin: 10px 0 20px 10px;
    white-space: nowrap;
}

.account__btn{
    width: 100%;
    margin-right: 0;
}

.account__avatar{
    height: 64px;
    width: 64px;
    border-radius: 50%;
    overflow: hidden;
}

.account__name{
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 15px;
    margin: 5px 0 5px 0px;
}

.account__sub{
    margin-top: 0;
    margin-bottom: 10px;
    color: #656262;
    font-size: 11px;
    line-height: 15px;
}

.account__forgot-password{
    position: absolute;
    font-size: 11px;
    line-height: 15px;
    bottom: -18px;
    right: 0;
}

.account__forgot-password a{
    color: #70bbfd;
}

.account__forgot-password a:hover{
    color: #3ea3fc;
    text-decoration: none;
}

.form__form-group--forgot{
    margin-bottom: 40px;
}

.account__or{
    text-align: center;
    margin-top: 35px;
    margin-bottom: 20px;
    position: relative;
}

.account__or:before, .account__or:after{
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #ddd;
    position: absolute;
    top: 10px;
}

.account__or:before{
    left: 0;
}

.account__or:after{
    right: 0;
}

.account__social{
    text-align: center;
}

.account__social-btn{
    display: inline-block;
    height: 38px;
    width: 38px;
    border-radius: 5px;
    padding: 9px;
    margin-right: 10px;
    transition: all 0.3s;
    border: none;
}

.account__social-btn:last-child{
    margin-right: 0;
}

.account__social-btn svg{
    fill: #fff;
    height: 20px;
    width: 20px;
}

.account__social-btn.account__social-btn--facebook{
    background: #4766a4;
}

.account__social-btn.account__social-btn--facebook:hover{
    background: #385080;
}

.account__social-btn.account__social-btn--google{
    background: #c74d4d;
}

.account__social-btn.account__social-btn--google:hover{
    background: #ab3636;
}

.account__head{
    margin-bottom: 30px;
    padding-left: 10px;
    border-left: 4px solid #70bbfd;
}

.account__logo{
    font-weight: 700;
}

.account__logo-accent{
    color: #70bbfd;
}

.account__have-account{
    text-align: center;
    margin-top: 20px;
}

.account__have-account a{
    color: #70bbfd;
    transition: all 0.3s;
}

.account__have-account a:hover{
    color: #3ea3fc;
    text-decoration: none;
}

@media screen and (max-width: 520px){
    .account__card{
        padding: 35px 30px;
    }
}

@media screen and (max-width: 425px){
    .account__btns{
        flex-wrap: wrap;
    }
}

.form-control{
    margin: 10px 0px;
}

.header-editor{
    background: #fff;
    outline: none;
    padding: 20px;
    border: 1px solid #b3b3b1;
}

.classification-banner {
    background-color: green;
    color: white;
    display: flex;
    justify-content: center;
}

.classification-banner-with-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.body-editor{
    outline: none;
    background: #fff;
    height: 100vh;
    padding: 20px;
    border: 1px solid #ccc;
}

.currentusers{
    margin: 20px 0;
    display: flex;
}

.navbar-brand{
    font-size: 14px;
}

.nav-items{
    display: flex;
    /*background: linear-gradient(90deg, rgb(152, 149, 149) 0%, rgb(40, 41, 41) 100%);*/
    background-color: rgb(40, 41, 41);
    height: 2.5rem;
    align-items: center;
    font-size: 1rem;
}

.nav-image{
    height: 100%;
    cursor: pointer;
    padding-left: 2vh;
}

.nav-menu{
    display: flex;
    list-style: none;
    margin-bottom: 0;
    flex-wrap: wrap;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5em;
}

.nav-links:hover{
    background-color: #979799;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.userInfo{
    width: 40px;
    height: 40px;
    display: -webkit-flex;
    display: flex;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF;
}

.container-fluid{
    padding: 0px;
}

.main-content{
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 50px;
    background: #eee;
}

.document-holder{
    flex: 1;
}

.history-holder{
    width: 300px;
    background: #FFF;
    min-width: 300px;
    margin: 80px 0px 90px 15px;
    height: 100vh;
    border: 1px solid #ccc;
}

.history-holder ul{
    padding: 9px;
    list-style: none;
    font-size: 12px;
    color: #4e4e4e;
}

.react-switch-checkbox{
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
}

.react-switch-label .react-switch-button{
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button{
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button{
    width: 60px;
}

.noPadding{
    padding: 0;
}

button, input[type="radio"]{
    cursor: pointer;
}

.borderedRow td{
    padding: 0.3em 0.5em;
    background-color: white;
}

.editableTableRow td{
    padding: 0.1em 0.5em;
    background-color: white;
}

.editableTable thead td{
    position: sticky;
    top: 0;
}

ul.useArrows li::marker{
    content: "\00BB \0020 \0020";
    color: #0a767a
}

h2{
    text-align: center;
}

.editableTable td:first-child{
    border-radius: 0.3em 0 0 0.3em;
}

.editableTable td:last-child{
    border-radius: 0 0.3em 0.3em 0;
    padding-right: 0.5em;
}

/* The slider itself */
.slider{
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 10px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover{
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb{
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 12px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #0A767A; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb{
    border: none;
    width: 12px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #0A767A; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.radarForm td, .telemetryForm td{
    padding: 5px;
}

.deleteBtn{
    color: white;
    backGround-color: red;
}

.successButton{
    color: white;
    background-color: green;
}

.container{
    position: relative;
}
.container>.backBtn{
    margin: 10px;
    position: absolute;
    right: -3em;
    top: 0;
    z-index: 1;
}

#userTable td, #userTable th{
    border: 0.1rem solid black;
    padding: 0.5em;
}

#runTable :is(td,th){
    padding: 0.5em;
    white-space: nowrap
}

#runTable tr td:first-child{
    border-right: 1px solid black;
}

#runTable tr:not(tr:last-child) td{
    border-bottom: 1px solid black;
}

.blackBody{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: -1;
}

.grayBody{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #b8b8b8;
    z-index: -1;
}

.overflowAuto{
    overflow-y: auto;
}

.pageContent{
    margin-bottom: auto;
    height: 100%;
}

.timeTable td{
    padding: 10px;
    font-size: 26px;
}

.utcTable td{
    padding-left: 5px;
}

.statusTable td{
    padding-right: 50px;
    white-space: nowrap;
}

#runsNext, #configurationPage{
    font-family: sans-serif;
}

:is(#runsNext, #configurationPage) button{
    border-radius: 0.5rem;
}

:is(#runsNext, #configurationPage) .removeBtn{
    background-color: red;
    color: white;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

:is(#runsNext, #configurationPage) .plusBtn{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

:is(#runsNext, #configurationPage) :is(h1, h2, h3, h4){
    text-align: center;
}

:is(#runsNext, #configurationPage) select{
    border-radius: 0.3rem;
    padding: 0.3rem;
}

:is(#runsNext, #configurationPage) input{
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

#configurationPage input[type="color"]{
    border-radius: 0.3rem;
    background-color: white;
}

.colorForm td{
    padding: 0.2rem;
}

/*=========== Text with a line on either side of it ===========*/
.lineText {
    position: relative;
    overflow: hidden;
    text-align: center;
}

.lineText:before, .lineText:after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    border-bottom: 1px solid lightgrey;
}

.lineText:before {
    margin-left: -52%; /*50% would leave no room between text and left line*/
    text-align: right;
}

/*Remove this to leave no room between text and right line*/
.lineText:after {
    margin-left: 2%;
    text-align: right;
}
/*=========================================*/

.mapPoint:hover{
    /*transform-origin: center;
    transform-box: fill-box;
    transform: scale(1.5);*/
    fill: blue;
}

.mapLine:hover{
    /*stroke-width: 4;*/
    stroke: blue;
}

.tableRow{
    white-space: nowrap !important;
}

*[data-tooltip] {
    position: relative;
}
*[data-tooltip]:hover:after {
    content: attr(data-tooltip);
    background-color: #000;
    color: #fff;
    top: 2em;
    right: 0;
    position: absolute;
    white-space: nowrap;
    z-index: 2;
}

.page-content-wrapper:has(:is(#runs, #users, #positions, #reports)){
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.page-content-wrapper:has(#runsNext) {
    height: 100%;
}

#runs, #users, #positions, #reports{
    display: flex;
    justify-content: center;
    overflow: hidden;

    .table-page-container{
        display: flex;
        flex-direction: column;
    }

    .MuiPaper-root{
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1500px){
    .nav-items{
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 1405px){
    .nav-items{
        font-size: 0.8rem;
        height: 2rem;
    }
}

@media screen and (max-width: 1310px){
    .nav-items{
        font-size: 0.7rem;
        height: 2rem;
    }
}



.logindiv {
    position: fixed;
    background-color: gray;
    height: 600px;
    width: 500px;
    margin: auto;
    top: 50%;
    left: 50%;
    margin-top: -300px;
    margin-left: -250px;
    border-radius: 0.5em 0.5em 0.5em 0.5em;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 100px;
}

@media screen and (max-width: 500px){
    .logindiv{
        position: fixed;
        background-color: gray;
        height: 80%;
        width: 90%;
        margin: 8% 5%;
        top: 0%;
        left: 0%;
        border-radius: 0.5em 0.5em 0.5em 0.5em;
        display: flex;
        flex-direction: column;
        text-Align: center;
        padding: 10%;
    }
}

@media screen and (max-height: 650px){
    .logindiv{
        position: fixed;
        background-color: gray;
        height: 80%;
        width: 90%;
        margin: 8% 5%;
        top: 0%;
        left: 0%;
        border-radius: 0.5em 0.5em 0.5em 0.5em;
        display: flex;
        flex-direction: column;
        text-Align: center;
        padding: 10%;
    }
}